<template>
  <div class="container">
    <b-row class=" mt-3" style="margin-bottom:30px">
      <b-col>
        <h1>{{ $t("News") }}</h1>
      </b-col>
      <b-col class="mt-4" style="text-align:right">
        <b-button
          style="width:100px"
          @click="$router.go(-1)"
          variant="outline-danger"
        >
          {{ $t("back") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(item, index) in News" :key="index" sm="6" md="3" lg="4">
        <div class="cursor-pointer mb-5" @click="GetInfo(item)">
          <div>
            <img
              class="w-100"
              :src="
                axios.defaults.baseURL + `News/GetNewsImage/${item.imageId}`
              "
              alt="news1"
            />
          </div>
          <div class="news-date my-2 px-2">{{ item.date }}</div>
          <h4 class="news-description px-2">
            {{ item.title }}
          </h4>
        </div>
      </b-col>
    </b-row>

    <b-sidebar v-model="sidebar" no-header width="100%" right>
      <div class="w-100 h-100 bg-white">
        <div class="cl-stages-accepting-applications">
          <div class="row justify-content-between pb-5">
            <div class="news-date mt-5 mb-3 px-2">
              <b-icon icon="calendar2-date"></b-icon
              ><span style="margin-right:15px;margin-left:2px">{{
                NewsInfo.date
              }}</span>
              <b-icon icon="eye"></b-icon
              ><span style="margin-left:2px">{{ NewsInfo.viewCount }}</span>
            </div>
            <p
              class="news-description px-2 mb-1 text-center"
              style="font-size:22px;font-weight:bold"
            >
              {{ NewsInfo.title }}
            </p>
            <div v-html="NewsInfo.content" class="mb-3 b"></div>
            <b-row>
              <b-col
                sm="12"
                lg="1"
                v-for="(item, index) in NewsInfo.tags"
                :key="index"
              >
                <a style="color:#0C2F75" href="">{{ item.name }}</a>
              </b-col>
            </b-row>
          </div>
        </div>
        <span
          class="close-btn"
          style="position: absolute; top: 0px; right: 0px; cursor: pointer"
          @click="sidebar = false"
        >
          <img src="images/design/fill-close.svg" alt="" />
        </span>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BPagination,
  BIconEyeFill,
  BSidebar,
} from "bootstrap-vue";
import axios from "axios";
import NewsService from "@/services/news.service";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BPagination,
    BIconEyeFill,
    BSidebar,
  },
  data() {
    return {
      sidebar: false,
      axios,
      NewsInfo: {},
      News: "",
      lang: "",
      filter: {
        search: "",
        sortBy: "",
        orderType: "",
        page: 0,
        pageSize: 0,
      },
    };
  },
  components: {},
  created() {
    this.lang = localStorage.getItem("locale") || "ru";
    this.Refresh();
  },
  methods: {
    Refresh() {
      NewsService.GetList(this.filter)
        .then((res) => {
          this.News = res.data.rows;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    GetInfo(item) {
      this.sidebar = true;
      NewsService.Get(item.id).then((res) => {
        this.NewsInfo = res.data;
      });
    },
  },
};
</script>
<style scoped>
.a >>> p {
  font-size: 14px !important;
  color: black !important;
}
.b >>> p {
  font-size: 14px !important;
  color: black !important;
}
.b >>> img {
  width: 70%;
}
.b >>> h1 {
  font-size: 16px !important;
  color: black !important;
  font-weight: bold;
}
.b >>> h2 {
  font-size: 16px !important;
  color: black !important;
  font-weight: bold;
}
.b >>> h3 {
  font-size: 16px !important;
  color: black !important;
  font-weight: bold;
}
.b >>> h4 {
  font-size: 16px !important;
  color: black !important;
  font-weight: bold;
}
.b >>> h5 {
  font-size: 16px !important;
  color: black !important;
  font-weight: bold;
}
</style>
